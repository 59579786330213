import React, { useEffect, useState, useCallback } from 'react'
import { Saved } from "components/elements/saved"

function LazyBackground({
  src,
  className,
  children,
  place,
  handleSave,
  isImage, // True if image is not placeholder
  isVisible,
  isSaved,
  isShowSaved,
  isShow,
  style
}) {
  const timeout = 500
  const [source, setSource] = useState(src)
  const [showImage, setShowImage] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isLowQuality, setIsLowQuality] = useState(false)
  const [imageStyle, setImageStyle] = useState({})

  const onLoad = () => {
    if (!imageLoaded) {
      setSource(src)
      setShowImage(true)
      setImageLoaded(true)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!imageLoaded) onLoad()
    }, timeout)
    return () => clearTimeout(timer)
  }, [imageLoaded, onLoad, timeout])

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      onLoad()
      const { width, height } = img
      const is_low_quality = height < 270
      setIsLowQuality(is_low_quality)
    }
  }, [src, onLoad])

  const containerClassname = `background-image${className ? ' ' + className : ''}`
  const hasImage = typeof source !== 'undefined'
  const savedIcon = (
    <Saved
      place={place}
      handleClick={handleSave}
      isSaved={isSaved}
    />
  )

  useEffect(() => {
    const newStyle = hasImage
      ? {
        background: style?.background,
        backgroundImage: source ? `url(${source})` : style?.background ? style.background : 'none',
        content: source ? `url(${source})` : style?.background ? style.background : 'none',
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
        //filter: isLowQuality ? 'blur(1px)' : 'none'
      }
      : {
        ...style
      }

    setImageStyle(newStyle)

  }, [source, style, isLowQuality])

  const isReady = imageLoaded || isVisible || showImage

  return (
    <div className='sing-card-image'>
      {isReady
        ? (
          <div
            className={containerClassname}
            onLoad={onLoad}
            style={imageStyle}
          >
            {isShowSaved ? savedIcon : ''}

            {children}
          </div>
        )
        : (
          <div className={`background-image`} ></div>
        )
      }
    </div>
  )
}

export default LazyBackground