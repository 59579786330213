import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const getReadTime = (string, wpm) => {
  const wordsPerMinute = wpm || 200
  let result
  // Strip html tags and split by words
  let textLength = string.replace(/(<([^>]+)>)/gi, "").split(" ").length
  if (textLength > 0) {
    let value = Math.ceil(textLength / wordsPerMinute)
    result = `${value} min read`
  }
  return result
}

const ReadTimeBadge = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query WPMQuery {
      wp {
        siteOptions {
          pageSlug
        }
      }
    }
  `)
  const defaultValue = 200
  let content = props.contentToAnalyze
  let wordsPerMinute = data?.wp?.siteOptions?.postOptions?.wordsPerMinute ? data.wp.siteOptions.postOptions.wordsPerMinute : defaultValue
  return (
    <div className="read-time-badge">
      {content && getReadTime(content, wordsPerMinute)}
    </div>
  )
}

export default ReadTimeBadge

ReadTimeBadge.propTypes = {
  content: PropTypes.string,
}
